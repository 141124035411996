@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
    font-family: 'Prompt' !important;
    font-size: 16px;
    background: #000000;
}
.theme--light.v-application{
    background: #000000;
}
.v-application{
    font-family: 'Prompt' !important;
}
.v-application .text-h5{
    font-family: 'Prompt' !important;
}
.btn-export{
    padding: 0.5rem;
    border: 1px solid;
    width: 10rem;
    border-radius: 5px;
    font-size: 0.9rem;
    margin: 0.5rem;
}
.btn-list{
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 0.5rem;
    padding-bottom: 1rem;
}
.reportrange-text{
    border: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.48) !important;
    position: relative;
    bottom: 4px;
}
.daterangepicker .ranges ul{
    display: flex;
    flex-direction: column;
}
.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #ED4E26;
    border-color: transparent;
    color: #fff;
}
.daterangepicker .ranges li.active {
    background-color: #ED4E26;
    color: #fff;
}

.btn_login{
    background-color: #4caf50 !important;
    border-color: #4caf50 !important;
}
.theme--light.v-icon {
    color: #F8CD6D
}

.menu_custon{
    position: relative;
    z-index: 5000;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.menu_custon ul{
    width: 80%;
    display: flex;
    justify-content: space-around;
}
.menu_custon ul li{
    list-style: none;
    cursor: pointer;
}
.menu_custon ul li a{
   color: #F8CD6D;
    font-size: 2rem;
}
ul.drop_down{
    display: none;
    padding-top: 5rem;
    position: absolute;
    top: -2rem;
    width: 15rem;
    font-size:1rem;
}

ul.drop_down li a{
    font-size:1.5rem;
    cursor: pointer;
    text-decoration: none;
}

.menu_custon ul li a:hover +  ul.drop_down{
    display: block;
}

ul.drop_down li a:active + ul.drop_down{
    display: none;
}
ul.drop_down:hover {
    display: block;
}
.drop_down li{
    padding: 0.2rem;
    background: #48090A !important;
}
.v-toast__item--warning{
    background: #eea60a;
}
.custom-container{
    align-items: stretch;
}
.custom-card-barcode{
    width: 100%;
    min-height: 100%;
    background: white;
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.text-barcode{
    font-size: 3rem;
}
.text-highlight-barcode{
    color: #ED4E26;
    font-weight: bold;
}
.keyboard_container-number{
    height: fit-content;
    background: #2c3e50;
    width: 30rem;
    /*display: none;*/
}
.show_number{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.verifiled{
    font-size: 1.5rem;
    color: white;
}
.verify-container{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.hg-theme-default .hg-button.hg-standardBtn {
    width: 20px;
    height: 90px;
    font-size: 3rem;
    color: #FE4E26;
}
.password-input{
    font-size: 2rem;
    text-align: center;
}
.password-input:disabled{
    font-size:2rem;
    text-align: center;
    color: white;
}
.password-input:focus-visible{
    border: none;
}
.row-custom{

}
.card-list-file{
    width: 100%;
}
.next_icon{
    width: 3.5rem;
    cursor: pointer;
    margin-block: 1rem;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #FE4E26;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #FE4E26;
}
.header-label{
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.header-label-dashboard{
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 1rem;
}
.header-label img{
    width: 2rem;
    height: auto;
    cursor: pointer;
}

.header-label-dashboard img{
    width: 3rem;
    height: auto;
    cursor: pointer;
}
